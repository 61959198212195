<template>
    <q-layout view="hHh lpr fFf" id="piattaforma-rami">

        <Header />

        <q-page-container>
            <router-view/>
        </q-page-container>

        <Footer />
    </q-layout>
</template>

<script>

import {mapFields} from "vuex-map-fields";

export default {
    name: "PiattaformaRami",
    data() {
        return {}
    },
    components: {
        Header: () => import("./Header"),
        Footer: () => import("./Footer"),
    },
    computed: {
        ...mapFields([
            "is_sezione_visible",
        ])
    },
    methods: {
    },
    async mounted() {
        // this.$store.state.is_sezione_visible = false;
    }
}
</script>
